<template>
    <div>
        <div class="search_bar">
            <v-select
				:items="cities"
                v-model="filter.from_location"
				placeholder="Откуда"
				prepend-inner-icon="mdi-map-marker"
				height="48"
				hide-details
				outlined
				dense
                :loading="citiesLoading"
                item-text="name"
                item-value="id"
			></v-select>
			<v-select
				:items="cities"
                v-model="filter.to_location"
				placeholder="Куда"
				prepend-inner-icon="mdi-map-marker"
				height="48"
				hide-details
				outlined
				dense
                :loading="citiesLoading"
                item-text="name"
                item-value="id"
			></v-select>
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-if="tab==0"
                        v-model="filter.from_date"
                        placeholder="Дата выезда"
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        outlined
                        height="48"
                        readonly
                        dense
                    ></v-text-field>
                    <v-text-field
                        v-else
                        readonly
                        v-model="multipleDates"
                        placeholder="Дата выезда"
                        prepend-inner-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        outlined
                        height="48"
                        dense
                    ></v-text-field>
                </template>
                <div class="tab_wrapper">
                    <v-tabs v-model="tab">
                        <v-tab>Только туда</v-tab>
                        <v-tab>Туда - обратно</v-tab>
                    </v-tabs>
                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-date-picker
                                v-model="filter.from_date"
                                no-title
                                :first-day-of-week="1"
                                :allowed-dates="$allowedDates"
                                @input="menu = false"
                            >
                            </v-date-picker>
                        </v-tab-item>
                        <v-tab-item>
                            <v-date-picker
                                v-model="filter.dates"
                                no-title
                                range
                                :first-day-of-week="1"
                                :allowed-dates="$allowedDates"
                                multiple
                            >
                            </v-date-picker>
                        </v-tab-item>
                    </v-tabs-items>
                    
                </div>
            </v-menu>
			<people-count ref="peopleCount" :isPensioner='true' :model='filter'/>
			<v-btn @click="find" color="primary" height="48" width="160">
				Найти
			</v-btn>
		</div>
		<near-dates v-if="nearDates.length" :nearDates='nearDates' @onClick='nearClick'/>
        <select-route v-for="item in items" :key="item.id" :item='item' @onClick='onClick'/>
    </div>
</template>

<script>
import peopleCount from '@/components/ui/peopleCount.vue'
import NearDates from '@/components/ui/nearDates.vue'
import SelectRoute from '@/components/trains/selectRoute.vue'
import { stationService } from '@/services/station.service.js'
import { trainService } from '@/services/trains.service.js'
export default {
    components: {
        peopleCount,
        NearDates,
        SelectRoute
    },
    data: () => ({
        tab: 0,
        nearDates: [],
        menu: false,
        filter: {
            adult: 1,
            child: 0,
            pensioner: 0,
            from_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            dates: []
        },
        cities: [],
        citiesLoading: false,
        items: []
    }),
    computed: {
        multipleDates() {
            let dates = this.filter.dates ?? []
            if (dates.length) {
                if (dates[0] > dates[1]) {
                    [dates[1], dates[0]] = [dates[0], dates[1]]
                }
            }
            return dates.join(' ~ ')
        }
    },
    created() {
        this.getCities()
        if (Object.keys(this.$route.query).length) {
            this.filter = {}
            if (this.$route.query.to_date) {
                this.tab = 1
                this.filter.adult = Number(this.$route.query.adult)
                this.filter.child = Number(this.$route.query.child)
                this.filter.pensioner = Number(this.$route.query.pensioner)
                this.filter.from_date = this.$route.query.from_date
                this.filter.to_date = this.$route.query.to_date
                this.filter.from_location = Number(this.$route.query.from_location)
                this.filter.to_location = Number(this.$route.query.to_location)
                this.filter.dates = [this.$route.query.from_date, this.$route.query.to_date]
            } else {
                this.tab = 0
                this.filter.adult = Number(this.$route.query.adult)
                this.filter.child = Number(this.$route.query.child)
                this.filter.pensioner = Number(this.$route.query.pensioner)
                this.filter.from_date = this.$route.query.from_date
                this.filter.from_location = Number(this.$route.query.from_location)
                this.filter.to_location = Number(this.$route.query.to_location)
            }
            this.find()
        }
    },
    methods: {
        async getCities() {
            const { results } = await stationService.getStation()
            this.cities = results
        },
        isValidSearch() {
            let is_valid = false
            if (this.tab==0) {
                is_valid = (Number(this.filter.adult)+Number(this.filter.child)+Number(this.filter.pensioner)) > 0 && this.filter.from_location && this.filter.to_location && this.filter.from_date
            } else if (this.tab = 1) {
                is_valid = (Number(this.filter.adult)+Number(this.filter.child)+Number(this.filter.pensioner)) > 0 && this.filter.from_location && this.filter.to_location && this.filter.dates.length > 1
            }
            if (!is_valid) {
                let message = 'Выберите все поля'
                this.$store.commit('ui/SET_NOTIFICATION', {show: true, message, color: 'error'})
            }
            return is_valid
        },
        async find() {
            if (!this.isValidSearch()) return
            try {
                this.$loading(true)
                let filter = {}
                if (this.tab == 1) {
                    if (this.filter.dates.length) {
                        filter.adult = this.filter.adult,
                        filter.child = this.filter.child, 
                        filter.pensioner = this.filter.pensioner, 
                        filter.from_location = this.filter.from_location,
                        filter.to_location = this.filter.to_location,
                        filter.from_date = new Date(this.filter.dates[0]).getTime() / 1000 // this.filter.dates[0],
                        filter.to_date = new Date(this.filter.dates[1]).getTime() / 1000
                        filter.is_rt = true
                    }
                } else {
                    filter = {
                        adult: this.filter.adult,
                        child: this.filter.child, 
                        pensioner: this.filter.pensioner, 
                        from_date: new Date(this.filter.from_date).getTime() / 1000, 
                        from_location: this.filter.from_location,
                        to_location: this.filter.to_location, 
                        is_rt: false
                    }
                }
                console.log(filter);
                const { results } = await trainService.getActiveTrains(filter)
                this.nearDates = (await trainService.getNearDates(filter)).results
                this.items = results
            } catch(e) {
                console.error(e);
            } finally {
                this.$loading(false)
            }
        },
        nearClick(item) {
            if (this.tab == 1) {
                let diff, aDay = 86400000,start_date = this.filter.dates[0],end_date = this.filter.dates[1];
                diff = Math.floor(
                (
                    Date.parse(
                    end_date.replace(/-/g, '\/')
                    ) - Date.parse(
                    start_date.replace(/-/g, '\/')
                    )
                ) / aDay);
                this.$set(this.filter.dates, 0, this.$moment(Number(item.near_date)*1000).format('YYYY-MM-DD'))
                this.$set(this.filter.dates, 1, this.$moment(Number(item.near_date)*1000).add(diff, 'days').format('YYYY-MM-DD'))
            } else {
                this.filter.from_date = this.$moment(Number(item.near_date)*1000).format('YYYY-MM-DD')
            }
            this.find()
        },
        onClick(item) {
            if (this.tab == 0) {
                this.$router.push({
                    path: `/booking/trains/ow/${item.id}/`,
                    query: {...this.filter, route_station: item.route_station_id}
                })
            } else {
                let query = {
                    adult: this.filter.adult,
                    child: this.filter.child, 
                    pensioner: this.filter.pensioner, 
                    from_location: this.filter.from_location, 
                    to_location: this.filter.to_location, 
                    from_date: this.filter.dates[0],
                    to_date: this.filter.dates[1],
                    route_station: item.route_station_id
                }
                this.$router.push({
                    path: `/booking/trains/rt/${item.id}/`,
                    query
                })
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.search_bar {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 160px;
	align-items: center;
}
</style>