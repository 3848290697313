<template>
  <div class="near_dates">
      <div class="near_dates__header">
          <img src="@/assets/images/ui/calendar.svg" alt="">
          Ближайшие <br> даты
      </div>
      <div
        v-for="date in nearDates"
        :key="date.id"
        class="near_dates__box"
        :class="{activeDate: date.is_selected}"
        @click="onClick(date)"
      >
        <p>{{$moment(Number(date.near_date*1000)).format('DD MMM, ddd')}}</p>
        <p>{{date.price}} KZT</p>
      </div>
  </div>
</template>

<script>
export default {
    props: {
        nearDates: Array
    },
    methods: {
        onClick(date) {
            this.$emit('onClick', date)
        }
    }
}
</script>

<style lang="scss" scoped>
.near_dates {
    // display: grid;
    // grid-template-columns: minmax(147px, 197px) 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #E9E9E9;
    grid-column-gap: 2px;
    box-shadow: 0px 10px 20px rgba(138, 149, 158, 0.25);
    margin-top: 20px;
    div {
        height: 100px;
        width: 100%;
        background: #fff;
    }
    &__header {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Gilroy', sans-serif;
        font-size: 16px;
        line-height: 19px;
        color: #021011;
        img {
            margin-right: 6px;
        }
    }
    &__box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transition: .2s;
        &:hover {
            background: rgba(82, 177, 186, 0.2);
        }
        p {
            font-family: 'Gilroy', sans-serif;
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 0;
            color: #FF8413;
            &:first-of-type {
                color: #021011;
                margin-bottom: 6px;
            }
        }
    }
    .activeDate {
        background: rgba(82, 177, 186, 0.2) !important;
    }
}
</style>